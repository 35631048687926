:root {
  --brand_colors_blue4: #1E429B;
  --brand_colors_blue3: #4368B1;
  --brand_colors_blue2: #7C97CD;
  --brand_colors_blue1: #CED7EE;
  --brand_colors_black: #000000;
  --brand_colors_darkGray: #58595B;
  --brand_colors_lightGray: #F1F2F2;
  --brand_colors_white: #FFFFFF;
  --brand_colors_acidGreen: #C9DA2B;
  --brand_colors_grassGreen: #7BC46E;
  --brand_colors_errorRed: #CF4E4E;
  --brand_colors_warningOrange: #FFB84D;
}

.app {
  text-align: center;
  background-color: var(--brand_colors_white);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.app-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--brand_colors_blue3);
  font-size: calc(10px + 2vmin);
  color: var(--brand_colors_black);
  margin: 0px 0px 20px 0px;
  height: 4em;
}

.bottom-hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: var(--brand_colors_blue3);
  font-size: 26px;
  color: var(--brand_colors_white);
  margin: 20px 0px 0px 0px;
  height: 3em;
  min-height: 90px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.bottom-hero-header {
  margin-top: 0px;
  margin-bottom: 0px;
}

.button-link {
  display: inline-block;
  padding: 8px 20px;
  clear: none;
  border-radius: 3px;
  background-color: var(--brand_colors_lightGray);
  color: var(--brand_colors_blue4);
  -webkit-transition: all 400ms ease;
  transition: all 400ms ease;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.2px;
  white-space: normal;
  text-decoration: none;
}

.button-link:hover {
  opacity: 0.8;
  text-decoration: none;
}

.column-option-container {
  display: flex
}

.company-name {
  font-weight: 600;
}

.container {
  background-color: var(--brand_colors_white);
  color: var(--brand_colors_black);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-page-text {
  margin-bottom: 30px;
}

.container-error {
  margin-top: 10px;
  color: var(--brand_colors_errorRed);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.standard-page-layout {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pdf-container {
  height: 100% !important;
  width: 100% !important;
  position: absolute !important;
  top: 0px !important;
  left: 0px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hidden {
  display: none;
}

.button-container-full {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  margin-top: 5px;
  margin-left: 5px;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 60%;
  max-height: 60vh;
  overflow: auto;
  border: 2px solid var(--brand_colors_blue1);
  background: var(--brand_colors_lightGray);
}

.file-row {
  height: 3em;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-width: 2px 0px 0px 0px;
  border-style: solid;
  border-color: var(--brand_colors_blue1);
}

.file-row:first-child {
  border-top: none;
  border-bottom: none;
}

.file-row:last-child {
  border-bottom: none;
}

.file-name {
  margin-left: 20px;
}

.secure-options {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.secure-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.tooltip {
  width: 20px;
  height: 20px;
  transform: translateY(4px);
}

.cancel-accept-button-container {
  display: flex;
}

.option-divider-text {
  text-decoration: underline;
}

.option-number {
  text-decoration: underline;
  margin-bottom: 10px;
}

.passphrase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.key-animation {
  margin-bottom: 20px;
  width: 200px;
}

.passphrase-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top {
  margin: 20px 0px 0px 0px;
}

.bottom {
  margin: 20px 0px 30px 0px;
}

.eye-span {
  display: flex;
  transform: translateX(-30px);
  cursor: pointer;
}

.secure-option-passphrase {
  width: 60%;
}

.passphrase-input {
  width: 18%;
  text-align: center;
  padding-right: 35px;
  padding-left: 35px;
}

.reveal_password {
  padding: 1px 2px;
}

.eye {
  width: 18px;
  height: 18px;
}

.passphrase-text-primary {
  font-size: 2.5vh;
  font-weight: 600;
}

.passphrase-text-secondary {
  font-size: 2.0vh;
  font-weight: 500;
}

.passphrase-error {
  margin-top: 10px;
  color: var(--brand_colors_errorRed);
}

.pro-con-container {
  display: flex;
  flex-direction: row;
}

.bullet-heavy {
  margin-right: 10px;
  font-weight: 900;
}

.pro-symbol {
  margin-right: 10px;
  font-weight: 900;
  color: var(--brand_colors_blue3)
}

.con-symbol {
  margin-right: 10px;
  font-weight: 900;
  color: var(--brand_colors_errorRed)
}

.profile-words {
  color: var(--brand_colors_blue4)
}

.option-text-container {
  text-align: left;
  margin-bottom: 20px;
  flex-grow: 1;
}

.help-text {
  text-align: left;
  font-weight: 500;
  font-size: 2.5vh;
  margin-bottom: 10px;
  margin-left: 10px;
}

.text-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-right: 30px;
}

.general-information {
  font-size: 2.5vh;
  margin-bottom: 30px
}

.standard-button {
  min-height: 40px;
  min-width: 150px;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--brand_colors_blue3);
  color: var(--brand_colors_white);
  border: 1px solid var(--brand_colors_blue3);
  font-size: 16px;
  font-weight: 600
}

.option-button {
  min-height: 40px;
  width: 175px;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--brand_colors_blue3);
  color: var(--brand_colors_white);
  border: 1px solid var(--brand_colors_blue3);
  font-size: 16px;
  font-weight: 600
}

.standard-button:hover {
  background-color: var(--brand_colors_blue2);
}

.standard-button:focus {
  outline: none;
}

.standard-button:active {
  position: relative;
  top: 1px;
}

.title {
  margin-bottom: 40px;
}

.file-button {
  margin-right: 15px;
  min-height: 25px;
  min-width: 90px;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--brand_colors_blue3);
  color: var(--brand_colors_white);
  border: 1px solid var(--brand_colors_blue3);
  font-size: 14px;
  font-weight: 600
}

.file-button:hover {
  background-color: var(--brand_colors_blue2);
}

.file-button:focus {
  outline: none;
}

.file-button:active {
  position: relative;
  top: 1px;
}

.loader-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 90px;
  margin: 15px;
}

.cancel-button {
  min-height: 40px;
  min-width: 150px;
  margin-right: 20px;
  border-radius: 3px;
  cursor: pointer;
  background-color: var(--brand_colors_lightGray);
  border: 1px solid #cdcdd0a8;
  color: var(--brand_colors_blue3);
  font-size: 16px;
  font-weight: 600
}

.cancel-button:hover {
  background-color: var(--brand_colors_white);
}

.cancel-button:focus {
  outline: none;
}

.logo {
  margin-right: 10%;
  height: 2em;
}

.logo-link {
  width: 300px
}

.triangle-animation {
  width: 125px;
  margin: 30px 0 px 20 px 0px
}

.verticalLine {
  border-left: thin solid var(--brand_colors_black);
  margin: 0px 50px 0px 50px;
}

.waiting-text {
  color: var(--brand_colors_blue3);
}


@media only screen and (max-width: 600px) {
  .column-option-container {
    display: flex;
    flex-direction: column;
  }

  .verticalLine {
    border-bottom: thin solid var(--brand_colors_black);
    margin: 30px 0px 20px 0px;
  }

  .bottom-hero-header {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
  }

  .passphrase-input {
    width: 50%;
  }

  .button-link {
    display: inline-block;
    padding: 8px 20px;
    clear: none;
    border-radius: 3px;
    background-color: var(--brand_colors_lightGray);
    color: var(--brand_colors_blue4);
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.2px;
    white-space: normal;
    text-decoration: none;
  }

  .passphrase-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}